import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DanceEventHttpService, DanceManagerHttpService, DanceSchoolHttpService, DanceSchoolStateService, quillCharacterCountValidator, TicketHttpService, TicketStateService } from '@platri/dfx-angular-core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import {
  DanceEventsInterface,
  DanceManagerUserGroupsInterface, FeesPaidByEnum,
  TicketDto,
  TicketInterface,
  TimeInterface,
  TimeOperations
} from '@platri/df-common-core';
import { Time } from '@platri/df-common-shared-graphql';
import { map } from 'rxjs/operators';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-create-event-ticket',
  templateUrl: './create-event-ticket.page.html',
  styleUrls: ['./create-event-ticket.page.scss']
})
export class CreateEventTicketPage implements OnInit, OnDestroy {
  danceEvents: DanceEventsInterface[] = [];
  danceEvent: DanceEventsInterface;
  dfmUserGroups: DanceManagerUserGroupsInterface[];

  parentFormGroup: UntypedFormGroup;
  ticketSettingsFormGroup: UntypedFormGroup;
  pricingFormGroup: UntypedFormGroup;
  salesSettingsFormGroup: UntypedFormGroup;
  taxPercentage : number;
  isVatChanged = false;

  private subscriptions = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private matSnackBar: MatSnackBar,
    private danceSchoolService: DanceSchoolStateService,
    private danceEventHttpService: DanceEventHttpService,
    private danceSchoolHttpService: DanceSchoolHttpService,
    private danceManagerHttpService: DanceManagerHttpService,
    private ticketHttpService: TicketHttpService,
    private ticketService: TicketStateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.loadData();
    this.getDanceEventFromRoute();
    this.initializeForms();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getDanceEventFromRoute(): void {
    if (this.activatedRoute.snapshot.data.targetObject) {
      this.danceEvent = this.activatedRoute.snapshot.data.targetObject;
    }
  }
  
  loadData(): void {
    this.loadAllDanceEvents();
    this.loadUserGroups();
  }

  loadAllDanceEvents(): void {
    this.subscriptions.add(
      this.danceEventHttpService.getAllByDanceManagerId(this.danceSchoolService.getSyncCurrentDanceSchool().id, true, true).subscribe((res) => {
        this.danceEvents = res;
      })
    );
  }
  
  loadUserGroups(): void {
    this.subscriptions.add(
      this.danceManagerHttpService.getDmUserGroupsByDanceManagerId({danceManagerId: this.danceSchoolService.getSyncCurrentDanceSchool().id}).subscribe({
        next: (data) => {
          this.dfmUserGroups = data;
        }
      })
    );
  }

  initializeForms(): void {
    this.initializeTicketSettingsFormGroup();
    this.initializePricingFormGroup();
    this.initializeSalesSettingsFormGroup();
    this.initializeParentFormGroup();
    if (this.danceEvent) {
      this.injectLoadedData();
      this.disableUnavailableFormFields();
    }
    if (this.ticketService.getSyncTicketToDuplicate()) {
      this.injectDataDuplicateTicket(this.ticketService.getSyncTicketToDuplicate());
      this.ticketService.sendTicketToDuplicate(null);
    }
  }
  
  injectDataDuplicateTicket(ticket: TicketInterface): void {
    let salesStartDate;
    let salesEndDate;
    let salesStartTime;
    let salesEndTime;
    if (ticket.saleStart) {
      salesStartDate = new Date(ticket.saleStart);
      salesStartDate.setTime(salesStartDate.getTime() + salesStartDate.getTimezoneOffset() * 60 * 1000);

      const startTime: TimeInterface = {
        hours: new Date(ticket.saleStart).getUTCHours(),
        minutes: new Date(ticket.saleStart).getUTCMinutes()
      };
      salesStartTime = this.timeToString(startTime);
    }
    if (ticket.saleEnd) {
      salesEndDate = new Date(ticket.saleEnd);
      salesEndDate.setTime(salesEndDate.getTime() + salesEndDate.getTimezoneOffset() * 60 * 1000);

      const endTime = new Time();
      endTime.hours = new Date(ticket.saleEnd).getUTCHours();
      endTime.minutes = new Date(ticket.saleEnd).getUTCMinutes();
      salesEndTime = this.timeToString(endTime);
    }
    this.parentFormGroup.patchValue(
      {
        ticketSettings: {
          event: this.danceEvent,
          name: ticket.name,
          description: ticket.description,
          availableQuantity: ticket.quantity
        },
        pricing: {
          price: +ticket.price / 100,
          tax: {
            percent: ticket.taxPercentage
          },
          feesPaidBy: ticket.feesPaidBy
        },
        salesSettings: {
          salesStartDate: salesStartDate,
          salesStartTime: salesStartTime ?? null,
          salesEndDate: salesEndDate,
          salesEndTime: salesEndTime ?? null,
          sellAsFollowerLeader: ticket.sellAsFollowerLeader,
          sellAsFollowerActive: ticket.sellAsFollowerActive,
          sellAsLeaderActive: ticket.sellAsLeaderActive
        }
      },
      { emitEvent: true, onlySelf: true }
    );
  }

  timeToString(time: Time): string | null {
    if (!time) {
      return null;
    }
    if (time.hours === 0 && time.minutes === 0) {
      return `00:00`;
    }
    if (!time.hours && !time.minutes) {
      return '';
    }
    if (time.hours < 10) {
      if (time.minutes < 10) {
        return `0${time.hours}:0${time.minutes}`;
      }
      return `0${time.hours}:${time.minutes}`;
    }
    if (time.minutes === 0) {
      return `${time.hours}:00`;
    }
    if (time.minutes < 10) {
      return `${time.hours}:0${time.minutes}`;
    }
    return `${time.hours}:${time.minutes}`;
  }

  initializeTicketSettingsFormGroup(): void {
    this.ticketSettingsFormGroup = this.fb.group({
      event: this.fb.control(null, [Validators.required]),
      name: this.fb.control('', [Validators.required, Validators.maxLength(100)]),
      description: this.fb.control('', [Validators.required, quillCharacterCountValidator(1000)]),
      availableQuantity: this.fb.control(null, [Validators.required, Validators.min(1), Validators.max(9999999)])
    });
  }

  initializePricingFormGroup(): void {
    this.pricingFormGroup = this.fb.group({
      price: this.fb.control(null, [Validators.required, Validators.min(0), Validators.max(9999999)]),
      tax: this.fb.group({
        percent: this.fb.control(null, [Validators.required, Validators.min(0), Validators.max(100)])
      }),
      feesPaidBy: this.fb.control(FeesPaidByEnum.CREATOR, [Validators.required])
    });
  }

  initializeSalesSettingsFormGroup(): void {
    this.salesSettingsFormGroup = this.fb.group(
      {
        salesStartDate: this.fb.control(null),
        salesStartTime: this.fb.control({
          value: null,
          disabled: true
        }),
        salesEndDate: this.fb.control(null),
        salesEndTime: this.fb.control(null),
        sellAsFollowerLeader: this.fb.control(false),
        sellAsFollowerActive: this.fb.control(true),
        sellAsLeaderActive: this.fb.control(true),
        danceManagerUserGroupIds: this.fb.control(null)
      },
      {
        validator: this.atLeastOne('sellAsFollowerActive', 'sellAsLeaderActive')
      }
    );

    this.subscriptions.add(
    this.salesSettingsFormGroup.valueChanges.subscribe((data) => {
      if (data.salesStartDate) {
        this.salesSettingsFormGroup.controls['salesStartTime'].enable({
          emitEvent: false
        });
        if (this.salesSettingsFormGroup.controls['salesStartTime'].value === null) {
          this.salesSettingsFormGroup.patchValue(
            {
              salesStartTime: '00:00'
            },
            {
              emitEvent: false
            }
          );
        } else {
          if (this.salesSettingsFormGroup.controls['salesEndTime'] !== null && new Date(this.salesSettingsFormGroup.controls['salesEndDate'].value).getTime() === new Date(this.salesSettingsFormGroup.controls['salesStartDate'].value).getTime() && this.salesSettingsFormGroup.controls['salesEndTime'].value <= this.salesSettingsFormGroup.controls['salesStartTime'].value) {
            this.salesSettingsFormGroup.patchValue(
              {
                salesEndTime: this.salesSettingsFormGroup.controls['salesStartTime'].value
              },
              {
                emitEvent: false
              }
            );
          }
        }
      } else {
        this.salesSettingsFormGroup.controls['salesStartTime'].disable({
          emitEvent: false
        });
        this.salesSettingsFormGroup.patchValue(
          {
            salesStartTime: null
          },
          {
            emitEvent: false
          }
        );
      }
      if (data.salesEndDate) {
        this.salesSettingsFormGroup.controls['salesEndTime'].enable({
          emitEvent: false
        });
        if (this.salesSettingsFormGroup.controls['salesEndTime'].value === null) {
          this.salesSettingsFormGroup.patchValue(
            {
              salesEndTime: '23:45'
            },
            {
              emitEvent: false
            }
          );
        }
      } else {
        this.salesSettingsFormGroup.controls['salesEndTime'].disable({
          emitEvent: false
        });
        this.salesSettingsFormGroup.patchValue(
          {
            salesEndTime: null
          },
          {
            emitEvent: false
          }
        );
      }
    }));
  }

  atLeastOne(...fields: string[]) {
    return (group: UntypedFormGroup): ValidationErrors | null =>
      fields.some((fieldName: string) => {
        const field = group.get(fieldName).value;
        return group.get('sellAsFollowerLeader').value ? field : true;
      })
        ? null
        : ({
            atLeastOne: 'At least one field has to be provided.'
          } as ValidationErrors);
  }

  initializeParentFormGroup(): void {
    this.parentFormGroup = this.fb.group({
      ticketSettings: this.ticketSettingsFormGroup,
      pricing: this.pricingFormGroup,
      salesSettings: this.salesSettingsFormGroup
    });
  }

  injectLoadedData(): void {
    this.taxPercentage = this.danceSchoolService.getSyncCurrentDanceSchool().vat ?? null;
    this.pricingFormGroup.get('tax.percent')?.valueChanges.subscribe(value => {
     if (value !==this.taxPercentage){
       this.taxPercentage = value;
       this.isVatChanged = true;
     }
    });
    
    this.parentFormGroup.patchValue({
      ticketSettings: {
        event: this.danceEvent
      },
      pricing: {
        tax: {
          percent: this.taxPercentage?? null
        }
      }
    });
  }

  disableUnavailableFormFields(): void {
    this.ticketSettingsFormGroup.controls.event.disable();
  }

  submit(): void {
    const createTicket: TicketDto = this.mapRawFormValueToDto();
    this.parentFormGroup.disable();
    this.subscriptions.add(
      this.ticketHttpService.create(createTicket).subscribe({
        next: (res) => {
          if (this.isVatChanged){
            this.danceSchoolHttpService.updateDanceSchoolById(this.danceSchoolService.getSyncCurrentDanceSchool().id, ({vat: this.taxPercentage})).pipe(
              map((result) => {
                this.danceSchoolService.sendCurrentDanceSchool(result);
                this.danceManagerHttpService.getAllDanceManagersByCurrentUser().pipe(
                  map((danceSchools) => danceSchools),
                  map((danceSchools) => this.danceSchoolService.sendCurrentDanceSchools(danceSchools))
                ).subscribe();
              })
            ).subscribe();
          }
          this.matSnackBar.open(this.translocoService.translate('DANCE_MANAGER_EVENT.SUCCESSFULLY_CREATED_TICKET'));
          this.parentFormGroup.enable();
          this.router.navigate(
            [
              'dance-manager',
              this.danceSchoolService.getSyncCurrentDanceSchool().id,
              'events',
              'preview',
              // @ts-ignore
              res['danceEvent']['id']
            ],
            { fragment: 'tickets' }
          );
        },
        error: (error) => {
          this.matSnackBar.open(this.translocoService.translate('DANCE_MANAGER_EVENT.CREATE_TICKET_ERROR'));
          this.parentFormGroup.enable();
        }
      })
    );
  }

  mapRawFormValueToDto(): TicketDto {
    const rawFormValue = this.parentFormGroup.getRawValue();
    let saleStartDateTime = null;
    let saleEndDateTime = null;
    if (rawFormValue.salesSettings.salesStartDate) {
      const saleStartDate = new Date(rawFormValue.salesSettings.salesStartDate);
      const salesStartTime = rawFormValue.salesSettings.salesStartTime;
      saleStartDateTime = DateTime.now()
        .setZone('UTC')
        .set({
          day: saleStartDate.getDate(),
          month: saleStartDate.getMonth() + 1,
          year: saleStartDate.getFullYear(),
          hour: TimeOperations.stringToTime(salesStartTime).hours,
          minute: TimeOperations.stringToTime(salesStartTime).minutes,
          second: 0,
          millisecond: 0
        })
        .toString();
    }
    if (rawFormValue.salesSettings.salesEndDate) {
      const saleEndDate = new Date(rawFormValue.salesSettings.salesEndDate);
      const salesEndTime = rawFormValue.salesSettings.salesEndTime;
      saleEndDateTime = DateTime.now()
        .setZone('UTC')
        .set({
          day: saleEndDate.getDate(),
          month: saleEndDate.getMonth() + 1,
          year: saleEndDate.getFullYear(),
          hour: TimeOperations.stringToTime(salesEndTime).hours,
          minute: TimeOperations.stringToTime(salesEndTime).minutes,
          second: 0,
          millisecond: 0
        })
        .toString();
    }
    return {
      name: rawFormValue.ticketSettings.name,
      description: rawFormValue.ticketSettings.description,
      danceEventId: rawFormValue.ticketSettings.event.id,
      quantity: rawFormValue.ticketSettings.availableQuantity,
      price: Math.round(+rawFormValue.pricing.price * 100),
      taxPercentage: rawFormValue.pricing.tax.percent,
      feesPaidBy: rawFormValue.pricing.feesPaidBy,
      saleStart: saleStartDateTime ?? null,
      saleEnd: saleEndDateTime ?? null,
      sellAsFollowerLeader: rawFormValue.salesSettings.sellAsFollowerLeader,
      sellAsFollowerActive: rawFormValue.salesSettings.sellAsFollowerActive,
      sellAsLeaderActive: rawFormValue.salesSettings.sellAsLeaderActive,
      danceManagerUserGroupIds: rawFormValue.salesSettings.danceManagerUserGroupIds
    };
  }

  navigateToEvent(): void {
    this.router.navigate(
      ['/', 'dance-manager', this.danceSchoolService.getSyncCurrentDanceSchool().id, 'events', 'preview', this.danceEvent.id],
      { fragment: 'tickets' }
    );
  }
}
