<ng-container *transloco="let t">
  <div class="flex-column w-100 search-container">
    <div class="w-100">
      
      <!-- Header -->
      <div class="align-center" style="gap: 12px; padding: 0 1rem; height: 80px;">
        <div class="search-bar w-100">
          <form [formGroup]="searchFormGroup" class="w-100">
            <input
              id="google-autocomplete-input"
              type="text"
              formControlName="address"
              placeholder="{{ t('SEARCH.PLACEHOLDER_SEARCH') }}"
              class="search-input"
              (keydown)="handleKeyDown($event)"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
              <mat-option *ngIf="isLoading">Loading...</mat-option>
              <ng-container *ngIf="!isLoading">
                <mat-option *ngFor="let option of searchOptions | async" [value]="option.description">
                  <div *ngIf="option.typeEnum === 'ADDRESS'" class="flex-row align-center-start">
                    <mat-icon style="margin-left: 8px">location_on</mat-icon>
                    <span style="display: flex; align-items: center">{{option.description}}</span>
                  </div>
                  <div *ngIf="option.typeEnum === 'STUDIO'" class="flex-row align-start-center">
                    <img 
                      class="logo-image"
                      style="margin-right: 10px"
                      [src]="option.imageUrl ?? DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER" 
                      [alt]="t('GENERIC_WRAPPER.DANCE_SCHOOL_PICTURE_ALT')"
                    />
                    <span style="display: flex; align-items: center">{{option.description}}</span>
                  </div>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </form>
          <div class="flex-row align-end-center">
            <button *ngIf="addressControl?.value !== ''" (click)="resetSearch()" mat-icon-button class="clear-search-icon-box" [disabled]="isLoading">
              <div [class]="isLoading ? 'clear-search-icon-loading' : 'clear-search-icon'">
                <mat-icon style="width: 24px; height: 24px;">clear</mat-icon>
              </div>
            </button>
            <button mat-icon-button class="search-icon-box" (click)="loadData()" [disabled]="isLoading" [class.spinner]="isLoading">
              <div [class]="isLoading ? 'search-icon-loading' : 'search-icon'">
                <mat-icon style="width: 24px; height: 24px;">search</mat-icon>
              </div>
            </button>
          </div>
        </div>
        <div *ngIf="filterFormGroup.controls.searchCategory.value !== SearchCategoryEnum.PARTNERS" (click)="openFilterDialog()" class="filter-icon">
          <mat-icon style="width: 24px; height: 24px;">tune</mat-icon>
        </div>
      </div>
      
      <!-- Categories Filter -->
      <div id="category-bar">
        <div class="custom-tab-group-box align-center">
          <div class="custom-tab-group align-center">
            <div class="custom-tab align-center" (click)="updateQueryParamsOnCategoryChanges(SearchCategoryEnum.EVENTS)">
              <div class="flex-column align-center h-100" [class]="filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.EVENTS ? 'active-tab' : 'inactive-tab'">
                <img [src]="'assets/icons/search-tab-icons/search-events.svg'" alt="" class="tab-icon"/>
                <span>{{ t('GENERIC_WRAPPER.EVENTS') }}</span>
              </div>
            </div>
            <div class="custom-tab align-center" (click)="updateQueryParamsOnCategoryChanges(SearchCategoryEnum.FESTIVALS)">
              <div class="flex-column align-center h-100" [class]="filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.FESTIVALS ? 'active-tab' : 'inactive-tab'">
                <img [src]="'assets/icons/search-tab-icons/search-festivals.svg'" alt="" class="tab-icon"/>
                <span>{{ t('GENERIC_WRAPPER.FESTIVALS') }}</span>
              </div>
            </div>
            <div class="custom-tab align-center" (click)="updateQueryParamsOnCategoryChanges(SearchCategoryEnum.COURSES)">
              <div class="flex-column align-center h-100" [class]="filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.COURSES ? 'active-tab' : 'inactive-tab'">
                <img [src]="'assets/icons/search-tab-icons/search-courses.svg'" alt="" class="tab-icon"/>
                <span>{{ t('GENERIC_WRAPPER.COURSES') }}</span>
              </div>
            </div>
            <div class="custom-tab align-center" (click)="updateQueryParamsOnCategoryChanges(SearchCategoryEnum.STUDIOS)">
              <div class="flex-column align-center h-100" [class]="filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.STUDIOS ? 'active-tab' : 'inactive-tab'">
                <img [src]="'assets/icons/search-tab-icons/search-studios.svg'" alt="" class="tab-icon"/>
                <span>{{ t('SEARCH.SEARCH_STUDIOS') }}</span>
              </div>
            </div>
            <div class="custom-tab align-center" (click)="updateQueryParamsOnCategoryChanges(SearchCategoryEnum.PARTNERS)">
              <div class="flex-column align-center h-100" [class]="filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.PARTNERS ? 'active-tab' : 'inactive-tab'">
                <img [src]="'assets/icons/search-tab-icons/search-partner.svg'" alt="" class="tab-icon"/>
                <span>{{ t('SEARCH.SEARCH_PARTNER') }}</span>
              </div>
            </div>
            <div class="custom-tab align-center" (click)="updateQueryParamsOnCategoryChanges(SearchCategoryEnum.BOOK_ME)">
              <div class="flex-column align-center h-100" [class]="filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.BOOK_ME ? 'active-tab' : 'inactive-tab'">
                <img [src]="'assets/icons/search-tab-icons/search-book-me.svg'" alt="" class="tab-icon"/>
                <span>{{ t('SEARCH.SEARCH_BOOKING') }}</span>
              </div>
            </div>
            <!--          <div class="custom-tab align-center disabled-tab">-->
            <!--            <div class="flex-column align-center" [class]="currentSearchCategory === SearchCategoryEnum.ROOMS ? 'active-tab' : 'inactive-tab'">-->
            <!--              <img [src]="'assets/icons/search-tab-icons/search-rooms.svg'" alt="" class="tab-icon"/>-->
            <!--              <span>{{ t('SEARCH.SEARCH_ROOMS') }}</span>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--          <div class="custom-tab align-center disabled-tab">-->
            <!--            <div class="flex-column align-center" [class]="currentSearchCategory === SearchCategoryEnum.USER ? 'active-tab' : 'inactive-tab'">-->
            <!--              <img [src]="'assets/icons/search-tab-icons/search-user.svg'" alt="" class="tab-icon"/>-->
            <!--              <span>{{ t('SEARCH.SEARCH_USER') }}</span>-->
            <!--            </div>-->
            <!--          </div>-->
          </div>
          
          <button
            *ngIf="!isFeatureUnavailableForWeb(filterFormGroup.controls.searchCategory.value)"
            (click)="openFilterDialog()"
            class="align-center filter-button"
            mat-stroked-button
            color="primary"
          >
            <mat-icon style="color: black;">tune</mat-icon>
            <span style="margin-left: 6px; color: black;">{{ 'Filter' }}</span>
          </button>
        </div>
      </div>
      
      <!-- Content-->
      <div [ngClass]="{'loading-transition': isLoading, 'loaded-transition transition-wrapper': !isLoading}" class="w-100 tab-container">
        <div class="tab-content" *ngIf="!isLoading">
          
          <!-- Content-List -->
          <div *ngIf="!featureIsUnavailable">
            <div *ngIf="shownElements.results.length > 0; else emptySearch" class="list-item-container">
              <div
                *ngFor="let element of shownElements.results; trackBy: trackByIndex; let last = last; let i = index;"
                #shownElementCards
                infiniteScroll
                [fromRoot]="true"
                [infiniteScrollContainer]="'#pageBody'"
                [infiniteScrollDistance]="3"
                [infiniteScrollThrottle]="300"
                (scrolled)="onScrollDown()"
                class="flex-column"
                style="place-self: center; max-width: 545px;"
              >
                <ng-container *ngIf="element">
                  <!-- Events/Festivals -->
                  <df-search-events-list-item
                    *ngIf="filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.EVENTS || filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.FESTIVALS"
                    [event]="element"
                  ></df-search-events-list-item>
                  
                  <!-- Courses -->
                  <df-search-courses-list-item
                    *ngIf="filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.COURSES"
                    [course]="element"
                  ></df-search-courses-list-item>
                  
                  <!-- Studios -->
                  <df-search-studios-list-item
                    *ngIf="filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.STUDIOS"
                    [danceSchool]="element"
                  ></df-search-studios-list-item>
                </ng-container>
                
                <ng-container *ngIf="!element">
                  <df-search-list-ad
                    [adType]="filterFormGroup.controls.searchCategory.value"
                    [titleTranslateKey]="getTitleTranslateKey()"
                    [descriptionTranslateKey]="getDescriptionTranslateKey()"
                    [imageSourceKey]="getImageSourceKey()"
                  ></df-search-list-ad>
                </ng-container>
              </div>
            </div>
          </div>
          
          <!-- Partner -->
          <df-unavailable-for-web *ngIf="isFeatureUnavailableForWeb(filterFormGroup.controls.searchCategory.value)"></df-unavailable-for-web>
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #emptySearch>
    <div *ngIf="!hasFailedLoadingNoConnection && !hasFailedLoadingInternalServer" class="align-center">
      <div class="flex-column-center-center w-100 my4 py4 gap-15">
        <div class="flex-column-center-center circle mt3">
          <img ngSrc="assets/images/no_search.svg" alt="no_search icon" height="170" width="171"/>
        </div>
        <ng-container
          *ngIf="filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.EVENTS || filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.FESTIVALS">
          <span class="no-search-title">{{ t('SEARCH.NO_SEARCH_RESULTS.EVENTS_TITLE') }}</span>
          <span>{{ t('SEARCH.NO_SEARCH_RESULTS.DESCRIPTION') }}</span>
        </ng-container>
        <ng-container
          *ngIf="filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.COURSES">
          <span class="no-search-title">{{ t('SEARCH.NO_SEARCH_RESULTS.COURSES_TITLE') }}</span>
          <span>{{ t('SEARCH.NO_SEARCH_RESULTS.DESCRIPTION') }}</span>
        </ng-container>
        <ng-container
          *ngIf="filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.STUDIOS">
          <span class="no-search-title">{{ t('SEARCH.NO_SEARCH_RESULTS.STUDIOS_TITLE') }}</span>
          <span>{{ t('SEARCH.NO_SEARCH_RESULTS.DESCRIPTION') }}</span>
        </ng-container>
      </div>
    </div>
    
    <div *ngIf="hasFailedLoadingNoConnection || hasFailedLoadingInternalServer" class="align-center">
      <div class="error-box" *ngIf="hasFailedLoadingNoConnection">
        <p style="color: red">{{ t('GENERIC_WRAPPER.NO_SERVER_CONNECTION') }}</p>
        <button mat-button color="primary" type="button" (click)="loadData()">{{ t('GENERIC_WRAPPER.TRY_AGAIN_SHORT') }}</button>
      </div>
    
      <div class="error-box" *ngIf="hasFailedLoadingInternalServer">
        <p style="color: red">{{ t('GENERIC_WRAPPER.UNSPECIFIED_ERROR') }}</p>
        <button mat-button color="primary" type="button" (click)="loadData()">{{ t('GENERIC_WRAPPER.TRY_AGAIN_SHORT') }}</button>
      </div>
    </div>
  </ng-template>
</ng-container>
