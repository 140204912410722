import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AppointmentParticipantDto,
  environmentForWeb,
  GetAllAppointmentParticipantCurrentUserRequestDto
} from '@platri/df-common-core';

@Injectable({
  providedIn: 'root'
})
export class AppointmentParticipantsHttpService {
  private SERVICE_URL = `${environmentForWeb.apiCoursesUrl}/appointment-participants`;

  constructor(private readonly httpClient: HttpClient) {}
  
  createHttp(appointmentId: string, userPassId: string): Observable<AppointmentParticipantDto> {
    return this.httpClient.post<AppointmentParticipantDto>(`${this.SERVICE_URL}`, {appointmentId, userPassId});
  }
  
  createWaitlistHttp(appointmentId: string): Observable<AppointmentParticipantDto> {
    return this.httpClient.post<AppointmentParticipantDto>(`${this.SERVICE_URL}/waitlist`, {appointmentId});
  }
  
  deleteHttp(appointmentId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.SERVICE_URL}/${appointmentId}`);
  }
  
  getAllByCurrentUser(dto: GetAllAppointmentParticipantCurrentUserRequestDto): Observable<AppointmentParticipantDto[]> {
    return this.httpClient.post<AppointmentParticipantDto[]>(`${this.SERVICE_URL}/current-user`, dto);
  }
}
