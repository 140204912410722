<ng-container *transloco="let t">
  <div class="appointment-card flex-column" style="gap: 20px;">
    
      <div class="flex-row-start-center" style="gap: 10px;">
        <div class="icon-wrapper">
          <mat-icon>calendar_today</mat-icon>
        </div>
        <div class="flex-column">
          <strong>{{
              appointment.date | dateTimezoned: 'UTC':currentLang:0:true
            }}</strong>
          <span>{{
              appointment.date
                | dateTimezoned: 'UTC':currentLang:appointment.duration:false:true
            }}</span>
        </div>
    </div>
    <div class="teachers-box" style="gap: 10px;">
      <div
        *ngFor="let teacher of appointment.teachers"
        [routerLink]="['/', '@' + teacher.username]"
        style="cursor: pointer; gap: 10px;"
        class="flex-row-center-center"
      >
        <img
          class="circle"
          style="max-width: 30px"
          [src]="teacher.imageUrl && teacher.imageUrl !== '' ? teacher.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER"
        />
        <div class="flex-row-center-center" style="gap: 3px;">
          <span *ngIf="teacher.firstName">{{ teacher.firstName }}</span>
          <span *ngIf="teacher.lastName">{{ teacher.lastName }}</span>
          <span *ngIf="!teacher.firstName && !teacher.lastName">{{
            teacher.username
          }}</span>
        </div>
      </div>
    </div>
    <div class="flex-row-start-center" style="gap: 10px;">
      <div class="icon-wrapper"><mat-icon>location_on</mat-icon></div>
      <div class="flex-column">
        <span *ngIf="appointment.location">{{ appointment.location.name }}</span>
        <span
          class="link"
          style="color: #4d4d4d;"
          target="_blank"
          (click)="openGoogleMapsInNewTab(appointment.address.googleMapsUrl)"
        >
          {{ appointment | googleAddressReadable: true }}</span
        >
      </div>
    </div>
    <div
      class="appointment-description flex-column-center-start"
      style="flex: 10px;"
      *ngIf="appointment.description"
    >
      <button
        mat-button
        type="button"
        class="details-btn fs16"
        (click)="showAppointmentDescription = !showAppointmentDescription"
      >
        <span>{{ t('GENERIC_WRAPPER.' + (!showAppointmentDescription ? 'SHOW_DETAILS' : 'HIDE_DETAILS')) }}</span>
        <mat-icon [ngClass]="showAppointmentDescription ? 'rotate' : ''"
          >expand_more</mat-icon
        >
      </button>
      <span
        *ngIf="showAppointmentDescription"
        [innerHTML]="appointment.description"
      ></span>
    </div>
    <div *ngIf="featureParticipate" class="flex-row-start-center" style="gap: 10px;">
      <div [style]="{cursor: isOwnerOrAdmin ? 'pointer' : 'none'}"s class="icon-wrapper" >
        <mat-icon (click)="routeToAppointmentParticipantList()">group</mat-icon>
      </div>
      <div class="flex-column">
        <span *ngIf="freeSpots > 1">{{ freeSpots }} {{ t('GENERIC_WRAPPER.FREE_SPOTS') }}</span>
        <span *ngIf="freeSpots === 1">{{ freeSpots }} {{ t('GENERIC_WRAPPER.FREE_SPOT') }}</span>
        <span *ngIf="freeSpots < 1">{{ t('GENERIC_WRAPPER.NO_FREE_SPOTS') }}</span>
      </div>
    </div>
    <div *ngIf="featureParticipate && isCheckInActive" class="flex-column">
      <df-shared-lib-button *ngIf="!openForParticipation && freeSpots <= 0 && !appointmentParticipant" [buttonLabel]="t('GENERIC_WRAPPER.COURSE_CHECK_IN.JOIN_WAITLIST')" (buttonClicked)="joinWaitlist()"></df-shared-lib-button>
      <df-shared-lib-button *ngIf="!openForParticipation && freeSpots > 0 && !appointmentParticipant" [buttonLabel]="t('GENERIC_WRAPPER.COURSE_CHECK_IN.RESERVE_SPOT')" (buttonClicked)="participate()"></df-shared-lib-button>
      <df-shared-lib-button *ngIf="!openForParticipation && appointmentParticipant?.status === AppointmentParticipantStatusEnum.WAITLIST" [materialButtonStyleType]="'outlined'" [buttonLabel]="t('GENERIC_WRAPPER.COURSE_CHECK_IN.YOU_ARE_ON_THE_WAITLIST')" (buttonClicked)="removeFromWaitlist()"></df-shared-lib-button>
      <df-shared-lib-button *ngIf="!openForParticipation && appointmentParticipant && appointmentParticipant?.status !== AppointmentParticipantStatusEnum.WAITLIST" [materialButtonStyleType]="'outlined'" [buttonLabel]="t('GENERIC_WRAPPER.COURSE_CHECK_IN.YOU_ARE_PARTICIPATING')" (buttonClicked)="removeParticipation()"></df-shared-lib-button>
      <df-shared-lib-button *ngIf="openForParticipation" [materialButtonStyleType]="'flat'" [buttonLabel]="t('GENERIC_WRAPPER.COURSE_APPOINTMENT_PARTICIPATE.ERROR.APPOINTMENT_MORE_THAN_THREE_DAYS')" [isDisabled]="true"></df-shared-lib-button>
    </div>
  </div>
</ng-container>
