import { Component, Input, OnInit } from '@angular/core';
import {
  DEFAULT_COURSE_PICTURE_PLACEHOLDER,
  DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER,
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER,
  NotificationInterface,
  NotificationUseCaseEnum,
  NotificationUseCaseSpecificDataInterface,
  UserAppLangEnum
} from '@platri/df-common-core';
import { NotificationHttpService } from '../../services';
import { TitleCasePipe } from '@angular/common';
import { TranslocoService } from '@jsverse/transloco';
import { DateTimezonedPipe } from '../../pipes';

interface UserNotificationItemInterface {
  imageUrl: string;
  title: string;
  text: string;
  routerLink: string[];
  createdAt: Date;
  readAt: Date;
}

@Component({
  selector: 'df-shared-lib-user-notification-item',
  templateUrl: './user-notification-item.component.html',
  styleUrls: ['./user-notification-item.component.scss'],
})
export class UserNotificationItemComponent implements OnInit {
  @Input() notification: NotificationInterface;
  
  userNotificationItem: UserNotificationItemInterface;
  currentLang: UserAppLangEnum;
  
  constructor(
    private translocoService: TranslocoService, 
    private notificationHttpService: NotificationHttpService, 
    private titleCasePipe: TitleCasePipe,
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }

  ngOnInit(): void {
    this.mapToUserNotificationItem();
  }
  
  onClick(): void {
    if (this.notification.readAt === null){
      this.notificationHttpService.markAsReadById(this.notification.id).subscribe();
    }
  }
  
  mapToUserNotificationItem(): void {
    switch (this.notification.notificationUseCase) {
      case NotificationUseCaseEnum.NEW_FOLLOW:
        this.mapFromNewFollowToUserNotificationItem();
        break;
      case NotificationUseCaseEnum.ADDED_TO_DANCE_MANAGER:
        this.mapFromAddedToDmToUserNotificationItem();
        break;
      case NotificationUseCaseEnum.GUEST_LIST_INVITATION:
        this.mapFromGuestListInvitationToUserNotificationItem();
        break;
      case NotificationUseCaseEnum.UPCOMING_EVENT:
        this.mapFromUpcomingEventToUserNotificationItem();
        break;
      case NotificationUseCaseEnum.USER_PASS_TRANSFERRED:
        this.mapFromUserPassTransferredToUserNotificationItem();
        break;
      case NotificationUseCaseEnum.USER_TICKET_TRANSFERRED:
        this.mapFromUserTicketTransferredToUserNotificationItem();
        break;
      case NotificationUseCaseEnum.FREE_PASS_TRANSFERRED:
        this.mapFromFreePassTransferredToUserNotificationItem();
        break;
      case NotificationUseCaseEnum.TRIAL_PASS_SEND:
        this.mapFromTrialPassSendToUserNotificationItem();
        break;
      case NotificationUseCaseEnum.COURSE_CREATED:
        this.mapFromNewCourseToUserNotificationItem();
        break;
      case NotificationUseCaseEnum.COURSE_APPOINTMENT_WAITLIST:
        this.mapFromCourseAppointmentWaitlistNotificationItem();
        break;
      case NotificationUseCaseEnum.EVENT_CREATED:
        this.mapFromNewEventToUserNotificationItem();
        break;
      case NotificationUseCaseEnum.SEARCH_POSTING_MATCH:
        this.mapFromMatchingFoundToUserNotificationItem();
        break;
      case NotificationUseCaseEnum.NOTIFY_FOLLOWERS_PARTICIPATE_EVENT:
        this.mapFromDanceEventToFollowerNotificationItem();
        break;
      case NotificationUseCaseEnum.APPOINTMENT_NOTIFICATION:
        this.mapFromAppointmentNotificationItem();
        break;
      default:
        break;
    }
  }
  
  mapFromNewFollowToUserNotificationItem(): void {
    const useCaseSpecificData = this.notification.useCaseSpecificData as NotificationUseCaseSpecificDataInterface;
    this.userNotificationItem = {
      readAt: this.notification.readAt,
      createdAt: this.notification.createdAt,
      imageUrl: useCaseSpecificData.fromTargetImageUrl,
      title: this.translocoService.translate('GENERIC_WRAPPER.NEW_FOLLOWER'),
      text: this.translocoService.translate('GENERIC_WRAPPER.HAS_STARTED_FOLLOWING', {username: useCaseSpecificData.fromTargetUserName}),
      routerLink: ['@' + useCaseSpecificData.fromTargetUserName]
    };
  }
  
  mapFromCourseAppointmentWaitlistNotificationItem(): void {
    const useCaseSpecificData = this.notification.useCaseSpecificData as NotificationUseCaseSpecificDataInterface;
    this.userNotificationItem = {
      readAt: this.notification.readAt,
      createdAt: this.notification.createdAt,
      imageUrl: useCaseSpecificData.imageUrl ?? DEFAULT_COURSE_PICTURE_PLACEHOLDER,
      title: "",
      text: this.translocoService.translate('GENERIC_WRAPPER.NEW_PLACES_ON_THE_COURSE', {courseName: useCaseSpecificData.courseName}),
      routerLink: ['dance-class', useCaseSpecificData.courseId]
    };
  }
  
  mapFromNewCourseToUserNotificationItem(): void {
    const useCaseSpecificData = this.notification.useCaseSpecificData as NotificationUseCaseSpecificDataInterface;
    this.userNotificationItem = {
      readAt: this.notification.readAt,
      createdAt: this.notification.createdAt,
      imageUrl: useCaseSpecificData.danceManagerLogoUrl ?? DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER,
      title: this.translocoService.translate('GENERIC_WRAPPER.NEW_COURSE_TITLE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.NEW_COURSE_TEXT', {danceManagerName: useCaseSpecificData.danceManagerName}),
      routerLink: ['dance-class', useCaseSpecificData.courseId]
    };
  }

  mapFromNewEventToUserNotificationItem(): void {
    const useCaseSpecificData = this.notification.useCaseSpecificData as NotificationUseCaseSpecificDataInterface;
    console.log(useCaseSpecificData.danceManagerLogoUrl);
    this.userNotificationItem = {
      readAt: this.notification.readAt,
      createdAt: this.notification.createdAt,
      imageUrl: useCaseSpecificData.danceManagerLogoUrl ?? DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER,
      title: this.translocoService.translate('GENERIC_WRAPPER.NEW_EVENT_TITLE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.NEW_EVENT_TEXT', {danceManagerName: useCaseSpecificData.danceManagerName}),
      routerLink: ['dance-event', useCaseSpecificData.danceEventId]
    };
  }
  
  mapFromAddedToDmToUserNotificationItem(): void {
    const useCaseSpecificData = this.notification.useCaseSpecificData as NotificationUseCaseSpecificDataInterface;
    this.userNotificationItem = {
      readAt: this.notification.readAt,
      createdAt: this.notification.createdAt,
      imageUrl: useCaseSpecificData.danceManagerLogoUrl ?? DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER,
      title: this.translocoService.translate('GENERIC_WRAPPER.ADDED_TO_DM_TITLE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.ADDED_TO_DM_TEXT', { role: this.titleCasePipe.transform(useCaseSpecificData.danceSchoolUserRole), danceManagerName: useCaseSpecificData.danceManagerName }),
      routerLink: ['dance-manager', useCaseSpecificData.danceSchoolId]
    };
  }

  mapFromGuestListInvitationToUserNotificationItem(): void {
    const useCaseSpecificData = this.notification.useCaseSpecificData as NotificationUseCaseSpecificDataInterface;
    this.userNotificationItem = {
      readAt: this.notification.readAt,
      createdAt: this.notification.createdAt,
      imageUrl: useCaseSpecificData.fromTargetImageUrl ?? DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER,
      title: this.translocoService.translate('GENERIC_WRAPPER.GUEST_LIST_INVITATION_TITLE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.GUEST_LIST_INVITATION_TEXT', {danceEventName: useCaseSpecificData.danceEventName}),
      routerLink: ['inventory']
    };
  }

  mapFromUserPassTransferredToUserNotificationItem(): void {
    const useCaseSpecificData = this.notification.useCaseSpecificData as NotificationUseCaseSpecificDataInterface;
    this.userNotificationItem = {
      readAt: this.notification.readAt,
      createdAt: this.notification.createdAt,
      imageUrl: useCaseSpecificData.fromTargetImageUrl ?? DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER,
      title: this.translocoService.translate('GENERIC_WRAPPER.USER_PASS_TRANSFER_TITLE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.USER_PASS_TRANSFER_TEXT', {fromUserName: useCaseSpecificData.fromTargetUserName, passName:useCaseSpecificData.passName}),
      routerLink: ['inventory', 'passes', useCaseSpecificData.userPassId]
    };
  }
  
  mapFromUserTicketTransferredToUserNotificationItem(): void {
    const useCaseSpecificData = this.notification.useCaseSpecificData as NotificationUseCaseSpecificDataInterface;
    this.userNotificationItem = {
      readAt: this.notification.readAt,
      createdAt: this.notification.createdAt,
      imageUrl: useCaseSpecificData.fromTargetImageUrl ?? DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER,
      title: this.translocoService.translate('GENERIC_WRAPPER.USER_TICKET_TRANSFER_TITLE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.USER_TICKET_TRANSFER_TEXT', {fromUserName: useCaseSpecificData.fromTargetUserName, ticketName: useCaseSpecificData.ticketName}),
      routerLink: ['inventory', 'tickets', useCaseSpecificData.danceEventId]
    };
  }

  mapFromUpcomingEventToUserNotificationItem(): void {
    const useCaseSpecificData = this.notification.useCaseSpecificData as NotificationUseCaseSpecificDataInterface;
    this.userNotificationItem = {
      readAt: this.notification.readAt,
      createdAt: this.notification.createdAt,
      imageUrl: useCaseSpecificData.fromTargetImageUrl ?? DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER,
      title: this.translocoService.translate('GENERIC_WRAPPER.UPCOMING_EVENT_TITLE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.UPCOMING_EVENT_TEXT', {danceEventName: this.titleCasePipe.transform(useCaseSpecificData.danceEventName), danceManagerName: useCaseSpecificData.danceManagerName}),
      routerLink: ['dance-event', useCaseSpecificData.fromDanceEventId]
    };
  }
  mapFromFreePassTransferredToUserNotificationItem(): void {
    const useCaseSpecificData = this.notification.useCaseSpecificData as NotificationUseCaseSpecificDataInterface;
    this.userNotificationItem = {
      readAt: this.notification.readAt,
      createdAt: this.notification.createdAt,
      imageUrl: useCaseSpecificData.fromTargetImageUrl ?? DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER,
      title: this.translocoService.translate('GENERIC_WRAPPER.FREE_PASS_TRANSFER_TITLE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.FREE_PASS_TRANSFER_TEXT', {danceManagerName: useCaseSpecificData.danceManagerName }),
      routerLink: ['inventory', 'passes', useCaseSpecificData.userPassId]
    };
  }
  
  mapFromTrialPassSendToUserNotificationItem(): void {
    const useCaseSpecificData = this.notification.useCaseSpecificData as NotificationUseCaseSpecificDataInterface;
    this.userNotificationItem = {
      readAt: this.notification.readAt,
      createdAt: this.notification.createdAt,
      imageUrl: useCaseSpecificData.fromTargetImageUrl ?? DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER,
      title: this.translocoService.translate('GENERIC_WRAPPER.TRIAL_PASS_TRANSFER_TITLE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.TRIAL_PASS_TRANSFER_TEXT', {danceManagerName: useCaseSpecificData.danceManagerName }),
      routerLink: ['inventory', 'passes', useCaseSpecificData.userPassId]
    };
  }

  mapFromMatchingFoundToUserNotificationItem(): void {
    const useCaseSpecificData = this.notification.useCaseSpecificData as NotificationUseCaseSpecificDataInterface;
    this.userNotificationItem = {
      readAt: this.notification.readAt,
      createdAt: this.notification.createdAt,
      imageUrl: useCaseSpecificData.fromTargetImageUrl ?? DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER,
      title: this.translocoService.translate('GENERIC_WRAPPER.USER_MATCH_FOUND_TITLE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.USER_MATCH_FOUND_TEXT'),
      routerLink: [] //todo: add dancePartnerSearchId to routing
    };
  }
  
  
  mapFromDanceEventToFollowerNotificationItem(): void {
    const useCaseSpecificData = this.notification.useCaseSpecificData as NotificationUseCaseSpecificDataInterface;
    this.userNotificationItem = {
      readAt: this.notification.readAt,
      createdAt: this.notification.createdAt,
      imageUrl: useCaseSpecificData.fromTargetImageUrl ?? DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER,
      title: this.translocoService.translate('GENERIC_WRAPPER.DANCE_EVENT_TO_FOLLOWER_TITLE', {fromUserName: useCaseSpecificData.fromTargetUserName}),
      text: this.translocoService.translate('GENERIC_WRAPPER.DANCE_EVENT_TO_FOLLOWER_TEXT', {fromUserName: useCaseSpecificData.fromTargetUserName, danceEventName: useCaseSpecificData.danceEventName}),
      routerLink: ['dance-event', useCaseSpecificData.danceEventId]
    };
  }

  mapFromAppointmentNotificationItem(): void{
    const useCaseSpecificData = this.notification.useCaseSpecificData as NotificationUseCaseSpecificDataInterface;
    this.userNotificationItem = {
      readAt: this.notification.readAt,
      createdAt: this.notification.createdAt,
      imageUrl: useCaseSpecificData.fromTargetImageUrl ?? DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER,
      title: this.translocoService.translate('GENERIC_WRAPPER.APPOINTMENT_NOTIFICATION_TITLE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.APPOINTMENT_NOTIFICATION_TEXT', {courseName: useCaseSpecificData.courseName, date: useCaseSpecificData.date}),
      routerLink: ['dance-class', useCaseSpecificData.courseId]
    };
  }

  protected readonly DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  protected readonly DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER = DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER;
}
