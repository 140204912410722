export enum NotificationUseCaseEnum {
  NEW_MESSAGE = 'NEW_MESSAGE',
  NEW_EVENT_CO_HOST_REQUEST = 'NEW_EVENT_CO_HOST_REQUEST',
  NEW_FOLLOW = 'NEW_FOLLOW',
  COURSE_CREATED = 'COURSE_CREATED',
  COURSE_APPOINTMENT_WAITLIST = 'COURSE_APPOINTMENT_WAITLIST',
  EVENT_CREATED = 'EVENT_CREATED',
  UPCOMING_EVENT = 'UPCOMING_EVENT',
  UPCOMING_EVENT_ONE_HOUR_BEFORE = 'UPCOMING_EVENT_ONE_HOUR_BEFORE',
  GUEST_LIST_INVITATION = 'GUEST_LIST_INVITATION',
  ADDED_TO_DANCE_MANAGER = 'ADDED_TO_DANCE_MANAGER',
  USER_TICKET_TRANSFERRED = 'USER_TICKET_TRANSFERRED',
  USER_PASS_SCANNED = 'USER_PASS_SCANNED',
  USER_PASS_TRANSFERRED = 'USER_PASS_TRANSFERRED',
  FREE_PASS_TRANSFERRED = 'FREE_PASS_TRANSFERRED',
  TRIAL_PASS_SEND = 'TRIAL_PASS_SEND',
  SEARCH_POSTING_MATCH = "SEARCH_POSTING_MATCH",
  NOTIFY_FOLLOWERS_PARTICIPATE_EVENT = "NOTIFY_FOLLOWERS_PARTICIPATE_EVENT",
  APPOINTMENT_NOTIFICATION = "APPOINTMENT_NOTIFICATION"
}
